<template>
  <div class="inside-page-content" ref="refPrint">
    <div class="paper all-center">
      <div class="form-title">知识产权维权申请表</div>
      <div style="width: 80%;margin-left: 10%;">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px" class="box">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名" prop="contactName">
                <el-input v-model="form.contactName" placeholder="请输入姓名" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号" prop="idNum">
                <el-input v-model="form.idNum" placeholder="请输入身份证号" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="contactPhone">
                <el-input v-model="form.contactPhone" placeholder="请输入联系电话" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电子邮籍" prop="contactEmail">
                <el-input v-model="form.contactEmail" placeholder="请输入电子邮籍" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="住址" prop="address">
                <el-input v-model="form.address" placeholder="请输入住址" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮编" prop="postCode">
                <el-input v-model="form.postCode" placeholder="请输入邮编" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="单位名称" prop="unitName">
                <el-input v-model="form.unitName" placeholder="请输入单位名称" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="营业执照" prop="businessLicense">
                <el-input v-model="form.businessLicense" placeholder="请输入营业执照" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="法人代表" prop="legalRepresentative">
                <el-input v-model="form.legalRepresentative" placeholder="请输入法人代表" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="unitContactPhone">
                <el-input v-model="form.unitContactPhone" placeholder="请输入联系电话" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="地址" prop="unitAddress">
                <el-input v-model="form.unitAddress" placeholder="请输入地址" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮编" prop="unitPostCode">
                <el-input v-model="form.unitPostCode" placeholder="请输入邮编" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="申请助内容" prop="aidContent">
                <el-input type="textarea" :rows="4"  v-model="form.aidContent" placeholder="请输入申请助内容" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="事实及理由" prop="factAccount">
                <el-input type="textarea" :rows="4"  v-model="form.factAccount" placeholder="请输入事实及理由" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="margin-left: 40px">
          <el-button type="primary" @click="submitForm">提 交</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'apply',
  components: {},
  data() {
    return {
      form: {},
      rules: {
        contactName: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        }],
        idNum: [{
          required: true,
          message: "请输入身份证号",
          trigger: "blur"
        }],
        contactPhone: [{
          required: true,
          message: "请输入联系电话",
          trigger: "blur"
        }],
        contactEmail: [{
          required: true,
          message: "请输入邮箱",
          trigger: "blur"
        }],
        address: [{
          required: true,
          message: "请输入住址",
          trigger: "blur"
        }],
        unitName: [{
          required: true,
          message: "请输入单位名称",
          trigger: "blur"
        }],
        businessLicense: [{
          required: true,
          message: "请输入营业执照",
          trigger: "blur"
        }],
        legalRepresentative: [{
          required: true,
          message: "请输入法人",
          trigger: "blur"
        }],
        unitContactPhone: [{
          required: true,
          message: "请输入联系电话",
          trigger: "blur"
        }],
        unitAddress: [{
          required: true,
          message: "请输入单位地址",
          trigger: "blur"
        }],
      },
    }
  },
  computed:{
  },
  created(options) {
  },
  mounted() {

  },
  methods: {
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$api.intellectualProperty.aidApply(this.form).then(response => {
            this.open = false;
            this.$router.go(-1);
          });
        }
      });
    },
    cancel: function() {
      this.$router.go(-1);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  .top-name{
    font-weight: 700;
  }
  .condition{
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin: 10px 0;
    position: relative;
    .btns {
      position: absolute;
      top: -9px;
      right: 0;
    }
  }

}
.form-title{
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin: 10px 0 50px 0;
}

</style>
